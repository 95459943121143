<template>
    <div class="input-box">
        <div class="code-item" :class="codeValue.length == 0 && inputFocus ? 'code-item-active' : ''">{{ codeValue[0] }}
        </div>
        <div class="code-item" :class="codeValue.length == 1 && inputFocus ? 'code-item-active' : ''">{{ codeValue[1] }}
        </div>
        <div class="code-item" :class="codeValue.length == 2 && inputFocus ? 'code-item-active' : ''">{{ codeValue[2] }}
        </div>
        <div class="code-item" :class="codeValue.length == 3 && inputFocus ? 'code-item-active' : ''">{{ codeValue[3] }}
        </div>
        <div class="code-item" :class="codeValue.length == 4 && inputFocus ? 'code-item-active' : ''">{{ codeValue[4] }}
        </div>
        <div class="code-item" :class="codeValue.length >= 5 && inputFocus ? 'code-item-active' : ''">{{ codeValue[5] }}
        </div>
        <input class="input-code" v-model="codeValue" :maxlength="6" type="tel" @blur="codeInputBlur"
            @focus="codeInputFocus" @input="codeInputChange" />
    </div>
</template>
  
<script>


export default {
    data() {
        return {
            time: 0,
            codeValue: '',
            inputFocus: false,
        }
    },
    created() {
        this.getVerifyCode();
    },
    methods: {
        // 获取验证码
        getVerifyCode() {
            this.time = 120;
            const timer = setInterval(() => {
                this.time--;
                if (this.time <= 0) {
                    this.time = 0;
                    clearInterval(timer);
                }
            }, 1000);
        },
        // 验证码输入框input
        codeInputChange() {
            if (this.codeValue && this.codeValue.length >= 6) {
                // do soming...
            }
        },
        // 验证码输入框失去焦点
        codeInputBlur() {
            this.inputFocus = false;
        },
        // 验证码输入框获取到焦点
        codeInputFocus() {
            this.inputFocus = true;
        },
    }
}
</script>

<style lang="scss" scoped>
.input-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 80px;
    position: relative;
    padding: 0px 15px;
    box-sizing: border-box;

    .input-code {
        width: 100%;
        height: 50px;
        position: absolute;
        left: 0px;
        top: 0px;
        box-sizing: border-box;
        color: transparent;
        background-color: transparent;
        opacity: 0;
    }

    .code-item {
        width: 40px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        border-bottom: 1px solid#D8D8D8;
        margin-right: 10px;
        color: #444;
        font-size: 34px;
    }

    .code-item-active {
        border-bottom: 1px solid#F23026;
    }
}
</style>   